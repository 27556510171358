
























































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { QuarterType, Quarter } from '@/types/quarters'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop() quarter!: Partial<Quarter>
  @Prop() updateData!: (data: Partial<Quarter>) => void
  private quarterId = ''
  private quarterName = ''
  private types = [QuarterType.Normal, QuarterType.Summer]
  private type = QuarterType.Normal
  private startDateDialog = false
  private startDate = new Date().toISOString().substr(0, 10)
  private endDateDialog = false
  private endDate = new Date().toISOString().substr(0, 10)
  private onEdit = false

  @Watch('show')
  onShowChange(val: boolean) {
    if (val) {
      this.quarterId = this.quarter.id + '' || ''
      this.quarterName = this.quarter.name || ''
      this.type = this.quarter.type || QuarterType.Normal
      if (this.quarter.startDate) {
        const date = new Date(this.quarter.startDate)
        date.setHours(date.getHours() + 7)
        this.startDate = date.toISOString().substr(0, 10)
      }
      if (this.quarter.endDate) {
        const date = new Date(this.quarter.endDate)
        date.setHours(date.getHours() + 7)
        this.endDate = date.toISOString().substr(0, 10)
      }
    }
  }

  private resetDialog() {
    this.quarterName = ''
    this.startDate = new Date().toISOString().substr(0, 10)
    this.endDate = new Date().toISOString().substr(0, 10)
  }

  private dateStrToDate(dateStr: string) {
    const [year, month, day] = dateStr.split('-')
    const date = new Date()
    date.setFullYear(+year, +month, +day)
    date.setHours(0, 0, 0, 0)
    return date
  }

  private async editQuarter() {
    this.onEdit = true
    try {
      const { data } = await this.$apollo.mutate<{
        editQuarter: Partial<Quarter>
      }>({
        mutation: gql`
          mutation PatchThisQuarter($data: EditQuarterInput!) {
            editQuarter(data: $data) {
              id
              name
              type
              startDate
              endDate
              isActive
              remark
            }
          }
        `,
        variables: {
          data: {
            id: +this.quarterId,
            name: this.quarterName,
            type: this.type,
            startDate: this.dateStrToDate(this.startDate).toISOString(),
            endDate: this.dateStrToDate(this.endDate).toISOString(),
          },
        },
      })
      if (data) {
        this.updateData(data.editQuarter)
      }
      this.isShow = false
    } catch (error) {
      // empty
    } finally {
      this.onEdit = false
    }
  }
}
