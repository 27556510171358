































































































































import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Quarter } from '@/types/quarters'
import AddQuarterDialog from '@/components/Management/AddQuarterDialog.vue'
import EditQuarterDialog from '@/components/Management/EditQuarterDialog.vue'

@Component({
  components: {
    AddQuarterDialog,
    EditQuarterDialog,
  },
})
export default class extends Vue {
  private quarters: Partial<Quarter>[] = []
  private headers = [
    { text: 'Name', value: 'name' },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Start', value: 'startDate' },
    { text: 'End', value: 'endDate' },
    { text: 'State', value: 'isActive', sortable: false },
    { text: 'Remark', value: 'remark', sortable: false, filterable: false },
    { text: 'Action', value: 'action', sortable: false, filterable: false },
  ]
  private onFetch = false
  private addDialog = false
  private changeStateDialog = false
  private selectedQuarter: Partial<Quarter> | null = null
  private selectType = ''
  private onEdit = false
  private editDialog = false
  private editedQuarter: Partial<Quarter> | null = null
  private remarks = [
    { text: 'No Remark', value: '' },
    { text: 'On Regis', value: 'onRegis' },
  ]

  private get alertText() {
    return this.selectType === 'changeActive'
      ? `Change active quarter to ${this.selectedQuarter?.name}`
      : `Delete ${this.selectedQuarter?.name}`
  }

  private get quarterList() {
    return this.quarters.sort((a, b) => {
      return (
        new Date(b.startDate || 0).getTime() -
        new Date(a.startDate || 0).getTime()
      )
    })
  }

  mounted() {
    this.fetchQuarter()
  }

  private async fetchQuarter() {
    this.onFetch = true
    try {
      const res = await this.$apollo.query<{ quarters: Partial<Quarter>[] }>({
        query: gql`
          query GetQuarters {
            quarters {
              id
              name
              type
              startDate
              endDate
              isActive
              remark
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      this.quarters = res.data.quarters
    } catch (error) {
      // empty
    } finally {
      this.onFetch = false
    }
  }

  private updateQuarter(data: Partial<Quarter>) {
    this.quarters.push(data)
  }

  private isoStrToDateStr(isoStr: string) {
    const date = new Date(isoStr)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  private async changeActiveQuarter() {
    this.onEdit = true
    try {
      const res = await this.$apollo.mutate<{
        setActiveQuarter: Partial<Quarter>[]
      }>({
        mutation: gql`
          mutation SetActiveQuarter($id: Int!) {
            setActiveQuarter(id: $id) {
              id
              name
              type
              startDate
              endDate
              isActive
              remark
            }
          }
        `,
        variables: {
          id: this.selectedQuarter?.id,
        },
      })
      this.quarters = res.data?.setActiveQuarter || []
    } catch (error) {
      // empty
    } finally {
      this.selectedQuarter = null
      this.selectType = ''
      this.changeStateDialog = false
      this.onEdit = false
    }
  }

  private async deleteQuarter() {
    this.onEdit = true
    try {
      const res = await this.$apollo.mutate<{
        deleteQuarter: { deletedId: number }
      }>({
        mutation: gql`
          mutation DeleteThisQuarter($id: Int!) {
            deleteQuarter(id: $id) {
              deletedId
            }
          }
        `,
        variables: {
          id: this.selectedQuarter?.id,
        },
      })
      this.quarters.splice(
        this.quarters.findIndex(
          el => el.id === res.data?.deleteQuarter.deletedId
        ),
        1
      )
    } catch (error) {
      // empty
    } finally {
      this.selectedQuarter = null
      this.selectType = ''
      this.changeStateDialog = false
      this.onEdit = false
    }
  }

  private updateQuarterValue(data: Partial<Quarter>) {
    const index = this.quarters.findIndex(el => el.id === data.id)
    if (index > -1) {
      this.$set(this.quarters, index, data)
    }
  }

  private async changeRemark(dto: Partial<Quarter>) {
    await this.$apollo.mutate<{
      editQuarter: Partial<Quarter>
    }>({
      mutation: gql`
        mutation PatchQuarterRemark($data: EditQuarterInput!) {
          editQuarter(data: $data) {
            id
            name
            type
            startDate
            endDate
            isActive
            remark
          }
        }
      `,
      variables: {
        data: {
          id: dto.id,
          remark: dto.remark,
        },
      },
    })
  }
}
