































































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { QuarterType, Quarter } from '@/types/quarters'

@Component
export default class extends Vue {
  @PropSync('show', { type: Boolean }) isShow!: boolean
  @Prop() updateData!: (data: Partial<Quarter>) => void
  private quarterId = ''
  private quarterName = ''
  private types = [QuarterType.Normal, QuarterType.Summer]
  private type = QuarterType.Normal
  private startDateDialog = false
  private startDate = new Date().toISOString().substr(0, 10)
  private endDateDialog = false
  private endDate = new Date().toISOString().substr(0, 10)

  private async addNewQuarter() {
    try {
      const { data } = await this.$apollo.mutate<{
        createQuarter: Partial<Quarter>
      }>({
        mutation: gql`
          mutation CreateQuarter($data: CreateQuarterInput!) {
            createQuarter(data: $data) {
              id
              name
              type
              startDate
              endDate
              isActive
              remark
            }
          }
        `,
        variables: {
          data: {
            id: +this.quarterId,
            name: this.quarterName,
            type: this.type,
            startDate: this.dateStrToDate(this.startDate).toISOString(),
            endDate: this.dateStrToDate(this.endDate).toISOString(),
          },
        },
      })
      if (data) {
        this.updateData(data.createQuarter)
      }
      this.resetDialog()
      this.isShow = false
    } catch (error) {
      // empty
    }
  }
  private resetDialog() {
    this.quarterId = ''
    this.quarterName = ''
    this.startDate = new Date().toISOString().substr(0, 10)
    this.endDate = new Date().toISOString().substr(0, 10)
  }

  private dateStrToDate(dateStr: string) {
    const [year, month, day] = dateStr.split('-')
    const date = new Date()
    date.setFullYear(+year, +month, +day)
    date.setHours(0, 0, 0, 0)
    return date
  }
}
